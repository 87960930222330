import React, { ChangeEvent, useState } from 'react'
import { BoxSimulator } from './style'

const SimuladorShellBoxCashback = () => {
  const [ newValue, setNewValue ] = useState<number>(800)
  const result = newValue * 0.475

  return (
    <BoxSimulator className='container p-3 pb-md-4 mt-3 pt-md-4'>
      <div className='px-3' />
      <div className='px-3 mt-2 mt-md-0 px-md-0'>
        <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-center text-grayscale--500 mb-md-2 mt-md-2'>
          Quanto você gasta com combustível por mês?
        </p>
        <p className='fs-18 lh-22 fs-lg-24 lh-lg-30 fw-600 text-center pt-md-3 pt-lg-0'>
          <span className='text-orange--extra fw-600'>R$ {newValue + ',00'}</span>
        </p>
        <span className='range-slider pb-md-3'>
          <input
            type='range'
            min={200}
            max={2200}
            value={newValue}
            step={400}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewValue((+e.target.value > 400) ? +e.target.value - 200 : +e.target.value)}
          />
          <span className='slider-container'>
            <span className='bar'>
              <span style={{ width: `${((newValue - 200) * 100) / 1800}%` }} />
            </span>
            <span className='bar-btn' style={{ left: `${((newValue - 200) * 100) / 1800}%` }} />
          </span>
        </span>
        <hr />
      </div>
      <div className='px-3 pt-2'>
        <p className='fs-14 lh-18 fs-md-18 lh-md-22 mb-0 text-grayscale--500 text-center mb-3'>
          Pagando pelo Super App Inter você economiza cerca de:
        </p>
        <p className='fs-28 lh-36 fs-lg-40 lh-lg-50 text-center fw-600 mb-2'>
          <span className='text-grayscale--500 text-center fw-600'>R$ {result + ',00'}</span>
        </p>
        <p className='fs-12 lh-16 fs-lg-14 lh-lg-17 cor text-center fw-600 mb-0'>em cashback por ano</p>
      </div>
    </BoxSimulator>
  )
}

export default SimuladorShellBoxCashback
