import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import SimuladorShellBoxCashback from '../simuladorShellBoxCashback/_index'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { OpenModalShellBoxProps } from '../../types'

const DescontoShellBox = ({ setOpenModal, dataLayer, setDataLayer, sendDatalayerEvent, url }: OpenModalShellBoxProps) => {
  const width = useWidth()

  return (
    <SectionComponent
      id='desconto-shell-box'
      minHeight={{ sm: '648px', md: '411px', lg: '427px', xl: '547px', xxl: '547px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 pr-md-0'>
        <h2 className='font-citrina text-primary--500 fs-20 lh-24 fs-md-24 lh-md-28'>Desconto Shell Box</h2>
        <h3 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-40 lh-xl-44 mb-4'>
          <span className='d-block'>Confira quanto dá pra</span> <span className='d-block d-lg-inline'>economizar com</span> combustíveis
        </h3>
        {width < WIDTH_MD &&
          <SimuladorShellBoxCashback />
        }
        <p className='text-grayscale--400 fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mt-4 mb-2 mb-md-4'>
          Pagando com Shell Box pelo Super App, você economiza de forma direta, afinal o cashback cai na mesma hora na sua conta!
        </p>
        {width <= WIDTH_MD
          ? (
            <a
              href={url}
              className='btn btn--lg bg-orange--extra text-white text-none mt-4'
              title='Quero economizar'
              onClick={() => {
                setDataLayer && setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Confira quanto dá pra economizar com combustíveis',
                  element_action: 'click button',
                  element_name: 'Quero economizar',
                  redirect_url: url,
                })
              }}
            >
              Quero economizar
            </a>
          )
        : (
          <button
            className='btn btn--lg bg-orange--extra text-white text-none mt-4 mt-md-1'
            title='Quero economizar'
            onClick={() => {
              setDataLayer && setDataLayer(dataLayer)
              setOpenModal && setOpenModal(true)
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: 'Confira quanto dá pra economizar com combustíveis',
                element_action: 'click button',
                element_name: 'Quero economizar',
              })
            }}
          >
            Quero economizar
          </button>
        )
      }
      </div>
      {width >= WIDTH_MD &&
        <div className='col-12 col-md-6'>
          <SimuladorShellBoxCashback />
        </div>
      }
    </SectionComponent>
  )
}

export default DescontoShellBox
