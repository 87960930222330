import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { CardsSeuJeitoSimples } from './styles'
import { WIDTH_LG } from 'src/styles/breakpoints'
import ScrollTo from 'src/components/ScrollTo'

const imageSimplesMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-simples/image.webp'
const imageSeguroMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-seguro/image.webp'
const imageRapidoMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-rapido/image.webp'
const imageEconomicoMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-economico/image.webp'

const imageSimplesTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-simples/image.webp'
const imageSeguroTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-seguro/image.webp'
const imageRapidoTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-rapido/image.webp'
const imageEconomicoTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1024-economico/image.webp'

const imageSimplesDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-simples/image.webp'
const imageSeguroDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-seguro/image.webp'
const imageRapidoDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-rapido/image.webp'
const imageEconomicoDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-economico/image.webp'

const SeuJeitoSimples = () => {
  const width = useWidth()

  const arrayCards = [
    {
      title: 'Simples',
      description: 'Faça tudo pelo Super App',
      image: imageSimplesMobile,
      imageTablet: imageSimplesTablet,
      imageDesktop: imageSimplesDesktop,
    },
    {
      title: 'Seguro',
      description: 'Não precisa usar o cartão físico',
      image: imageSeguroMobile,
      imageTablet: imageSeguroTablet,
      imageDesktop: imageSeguroDesktop,
    },
    {
      title: 'Rápido',
      description: 'Pague em poucos cliques',
      image: imageRapidoMobile,
      imageTablet: imageRapidoTablet,
      imageDesktop: imageRapidoDesktop,
    },
    {
      title: 'Econômico',
      description: 'Ganhe cashback ao abastecer',
      image: imageEconomicoMobile,
      imageTablet: imageEconomicoTablet,
      imageDesktop: imageEconomicoDesktop,
    },
  ]

  return (
    <SectionComponent
      id='seu-jeito-simples'
      SectionStyles='bg-grayscale--100'
      minHeight={{ sm: '742px', md: '734px', lg: '496px', xl: '595px', xxl: '595px' }}
    >
      <div className='col-12'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-48 lh-xl-52 mb-4 text-md-center'>
          <span className='d-block d-md-inline'>Seu jeito simples de</span> abastecer
        </h2>
        <div className='d-md-flex align-items-md-center justify-content-md-center pt-2'>
          <div className='row'>
            {arrayCards.map((item: {title: string; description: string; image: string; imageTablet: string; imageDesktop: string }) => (
              <div className='col-6 col-lg-3 mb-4' key={item.title}>
                <CardsSeuJeitoSimples
                  style={{ backgroundImage: `url(${(width < WIDTH_MD && item.image) || (width === WIDTH_MD && item.imageTablet) || (width >= WIDTH_LG && item.imageDesktop) })` }}
                  className='col-12'
                >
                  <span className='font-citrina text-white fs-16 lh-20 fs-md-20 lh-md-25 fs-xl-28 lh-xl-33 mb-2 fw-600'>
                    {item.title}
                  </span>
                  <p className='text-white fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mb-0'>
                    {item.description}
                  </p>
                </CardsSeuJeitoSimples>
              </div>
              ))}
          </div>
        </div>

        {width <= WIDTH_MD
          ? (
            <ScrollTo
              to='#e-simples-usar-o-shell-box'
              styles='btn btn--lg bg-orange--extra text-white text-none mt-4 mx-auto d-flex'
              section='dobra_02'
              sectionName='Seu jeito simples de abastecer'
              elementName='Como pagar com Shell Box'
              title='Como pagar com Shell Box'
            >
              Como pagar com Shell Box
            </ScrollTo>
          )
        : (
          <ScrollTo
            to='#e-simples-usar-o-shell-box'
            styles='btn btn--lg bg-orange--extra text-white text-none mt-4 mx-auto d-flex'
            section='dobra_02'
            sectionName='Seu jeito simples de abastecer'
            elementName='Como pagar com Shell Box'
            title='Como pagar com Shell Box'
          >
            Como pagar com Shell Box
          </ScrollTo>
        )
      }
      </div>
    </SectionComponent>
  )
}

export default SeuJeitoSimples
