import styled from 'styled-components'

export const ListShellBox = styled.ul`
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    span {
      &:first-child {
        padding-top: 13px;
        padding-bottom: 13px;
      }
    }
  }
`
