import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

type ImageSectionProps = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export const ImageSection = styled.div`
  background: url(${(props: ImageSectionProps) => props.sm });
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-radius: 10px;
  position: relative;
  left: 7.5%;
  right: 7.5%;
  width: 85%;
  height: 100%;
  
  @media(min-width: ${breakpoints.md}){
    background: url(${(props: ImageSectionProps) => props.md});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    left: 0;
    right: 0;
  }

  @media(min-width: ${breakpoints.lg}){
    background: url(${(props: ImageSectionProps) => props.lg});
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${grayscale[500]};
  }

  @media(min-width: ${breakpoints.xl}){
    background: url(${(props: ImageSectionProps) => props.xl});
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const Section = styled.section`
  display: flex;
  justify-content: center;
  max-width: 100%;
  background-color: ${grayscale[100]} ;

  .dots {
    position: static;
    margin-top: 10px;

    li button {
      border: none;
      background: ${grayscale[400]};
    }

    .react-multi-carousel-dot--active button {
      background: ${orange.extra};
    }
  }

  .container {
    @media (min-width: 769px) {
      max-width: 100%;
      margin: none;
      padding: 0;
    }
  }

  .react-multi-carousel-track {
    @media (min-width: ${breakpoints.lg}) {
      padding-left: 125px;
    }
  }

  .gatsby-image-wrapper {
    position: initial !important;
  }
`

export const Figure = styled.figure`
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  border-radius: 16px;

  @media (min-width: ${breakpoints.md}) {
    height: 300px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 330px;
  }
  @media (min-width: ${breakpoints.xl}) {
    height: 400px;
  }
  @media (min-width: ${breakpoints.xxxl}) {
    height: 440px;
  }
  @media (min-width: ${breakpoints.xxll}) {
    height: 540px;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Circle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${white};
  outline: none;
  border: none;
  padding: 0;

  @media (min-width: ${breakpoints.md}){
    width: 32px;
    height: 32px;
  }

  &:focus {
    outline: none;
  }

  &.left {
    left: 22px;
  }

  &.right {
    right: 22px;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }
`
