import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 471px;

  @media ${device.tablet} {
    min-height: 475px;
  }
  @media ${device.desktopLG} {
    min-height: 501px;
  }
  @media ${device.desktopXL} {
    min-height: 588px;
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color:  ${grayscale[400]};
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    svg {
      fill: ${grayscale[400]};
    }
  }

  .react-multiple-carousel__arrow--left {
    svg {
      fill:  ${grayscale[400]};
    }
  }
`

export const Card = styled.div`
  width: 100%;
  height: 237px;
  border-radius: 8px;
  background: ${white};
  padding: 24px;
  box-shadow: 0px 8px 16px 2px rgba(22, 22, 22, 0.08);

  @media ${device.tablet} {
    height: 201px;
  }
  @media ${device.desktopLG} {
    height: 215px;
  }
`
