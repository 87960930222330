import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardsSeuJeitoSimples = styled.div`
  min-height: 222px;
  border-radius: 16px;
  gap: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: start;
  justify-content: flex-end;
  padding: 16px;
  flex-direction: column;

  @media ${device.desktopXL} {
    padding: 24px;
  }
`
