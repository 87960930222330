import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

const heroBannerTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-768-shellbox/image.webp'
const heroBannerDesktopLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/1024-bg-shellbox/image.webp'
const heroBannerDesktopXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-1440-shelbox/image.webp'

export const SectionHeroShellBox = styled.section`
  background: ${primary[500]};
  min-height: 708px;
  padding: 40px 0;

  @media ${device.tablet} {
    background-image: url(${heroBannerTablet});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 487px;
  }
  @media ${device.desktopLG} {
    background-image:  url(${heroBannerDesktopLG});
    min-height: 523px;
  }
  @media ${device.desktopXL} {
    background-image:  url(${heroBannerDesktopXL});
    min-height: 667px;
  }
`
