import React from 'react'
import Faq from 'src/components/Faq'

import faqData from '../../pageContext.json'

const FaqShellBox = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-8 col-xl-10 mx-auto'>
            <h2 className='font-citrina fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-48 lh-xl-52 mb-5 text-grayscale--500 fw-600 text-md-center'>
              Perguntas frequentes
            </h2>
            <p className='fs-14 fs-md-6 lh-18 lh-md-22 text-grayscale--400'>
              O Shell Box é um serviço que permite o pagamento de combustível diretamente pelo aplicativo, sem a necessidade de utilizar dinheiro ou cartões físicos.
            </p>
            <p className='fs-14 fs-md-6 lh-18 lh-md-22 text-grayscale--400'>
              Para pagar com Shell Box, é só ir até um Posto Shell que aceite o pagamento via app Shell Box. Ao chegar no posto, escolha a bomba desejada e confirme a transação dentro do Super App Inter.
            </p>
            <p className='fs-14 fs-md-6 lh-18 lh-md-22 text-grayscale--400'>
              É só acessar a área de Shell Box, inserir o código de pagamento que estará na placa ao lado da bomba de combustível e selecionar a opção "Abastecer".
            </p>
            <p className='fs-14 fs-md-6 lh-18 lh-md-22 text-grayscale--400 mb-5'>
              E se você está procurando por <strong>cupom Shell Box</strong> ou outras promoções, saiba que o melhor jeito de economizar no dia a dia é fazer sempre os pagamentos no posto de gasolina usando a funcionalidade dentro do Super App Inter. Assim, parte do valor pago volta pra você direto na conta. Aproveita!
            </p>
            <Faq
              columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
              searchBg='#F5F6FA'
              answerData={faqData.structuredData.faq}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqShellBox
