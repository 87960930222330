import styled from 'styled-components'
import { rounded } from 'src/styles/rounded'
import { device } from 'src/styles/breakpoints'
import { gray, green, orange, white } from 'src/styles/colors'

export const BoxSimulator = styled.div`
  width: 100%;
  background: white;
  border-radius: ${rounded['10']};
  box-shadow: 0 7.236842155456543px 21.710525512695312px 0 #4B4E5C1A;

  p.cor {
    color: ${green[400]};
  }

  hr {
    @media ${device.tablet} {
      margin: 20px 30px;
    }
  }

  .range-slider {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 100%;
    z-index: 0;

    @media ${device.tablet} {
      width: 70%;
      left: 13%;
    }

    & > input {
      cursor: grab;
      opacity: 0;
      position: relative;
      width: 100%;
      z-index: 5;

      &::-webkit-slider-thumb {
        appearance: none;
        border-radius: 10px;
        height: 30px;
        width: 50px;
        position: relative;
        z-index: 100;
      }
    }

    & > span.slider-container {
      box-sizing: border-box;
      display: inline-block;
      left: -8px;
      right: 46px;
      position: absolute;
      z-index: 3;

      & > span.bar {
            background-color: ${gray[500]};
            border-radius: 10px;
            display: inline-block;
            left: 22px;
            height: 8px;
            overflow: hidden;
            position: absolute;
            right: -42px;
            top: 10px;
            z-index: 1;
        & > span {
          background: linear-gradient(2.37deg, ${orange.dark} 0%, ${orange.base} 100%);
          display: block;
          float: left;
          height: 11px;
        }
      }
      & > span.bar-btn {
        display: inline-block;
        left: 0;
        position: absolute;
        top: -62px;

        &:after {
          content: "";
          background-color: ${white};
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05);
          border-radius: 20px;
          height: 22px;
          left: 22px;
          position: absolute;
          top: 65px;
          width: 22px;
          z-index: 3;
        }
      }
    }
  }
`
