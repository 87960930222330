import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'

import { OpenModalShellBoxProps } from '../../types'
import { WIDTH_MD } from 'src/styles/breakpoints'

const EcontreOposto = ({ dataLayer, setDataLayer, sendDatalayerEvent }: OpenModalShellBoxProps) => {
  const url = 'https://intergo.app/5bc331a2'
  const urlMobileTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-map/image.webp'
  const urlDesktopLgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-map/image.webp'
  const width = useWidth()

  return (
    <SectionComponent
      id='desconto-shell-box'
      SectionStyles='bg-white'
      minHeight={{ sm: '590px', md: '378px', lg: '454px', xl: '586px', xxl: '586px' }}
    >
      <div className='col-12 col-md-6 col-lg-5'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-40 lh-xl-44 mb-4'>
          Encontre o Posto Shell mais próximo de você
        </h2>
        {width < WIDTH_MD &&
          <ImageWebp
            pathSrc={urlMobileTablet}
            altDescription='Imagem ilustrativa de um mapa de uma cidade com marcações de localização com o símbolo da Shell.'
            arrayNumbers={[ 322 ]}
            arrayNumbersHeight={[ 231 ]}
            className='mx-auto d-block'
          />
        }
        <p className='text-grayscale--400 fs-14 lh-17 fs-xl-18 lh-xl-22 mt-4 mb-0'>
          Para encontrar o posto Shell mais próximo, acesse o localizador, digite seu endereço e identifique onde há postos perto de você.
        </p>
        <p className='text-grayscale--400 fs-14 lh-17 fs-xl-18 lh-xl-22 mb-4'>
          Ao chegar no local, confirme se o estabelecimento aceita pagamento via app Shell Box. Se sim, use a funcionalidade dentro do Super App Inter pra pagar e ganhar cashback.
        </p>
        <a
          href={url}
          className='btn btn--lg bg-orange--extra text-white text-none mt-2'
          title='Localizar postos por perto'
          target='_blank'
          rel='noreferrer'
          onClick={() => {
            setDataLayer && setDataLayer(dataLayer)
            sendDatalayerEvent({
              section: 'dobra_05',
              section_name: 'Encontre o Posto Shell mais próximo de você',
              element_action: 'click button',
              element_name: 'Localizar postos por perto',
              redirect_url: url,
            })
          }}
        >
          Localizar postos por perto
        </a>
      </div>
      {
        width >= WIDTH_MD &&
          <div className='col-md-6'>
            <ImageWebp
              pathSrc={urlDesktopLgXl}
              altDescription='Imagem ilustrativa de um mapa de uma cidade com marcações de localização com o símbolo da Shell.'
              arrayNumbers={[ 312, 346, 456, 551, 551 ]}
              arrayNumbersHeight={[ 221, 249, 326.07, 394, 394 ]}
              className='mx-auto d-block'
            />
          </div>
      }
    </SectionComponent>
  )
}

export default EcontreOposto
