import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import { WIDTH_MD } from 'src/styles/breakpoints'

import { OpenModalShellBoxProps } from '../../types'
import { SuperDicas, GridTextTitle, GridText } from './styles'

const imageMobileOne = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-blog1/image.webp'
const imageMobileTwo = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-360-blog2/image.webp'
const imageMobileThree = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-blog3/image.webp'

const imageDesktopOne = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-blog1/image.webp'
const imageDesktopTwo = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-blog2/image.webp'
const imageDesktopThree = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-blog3/image.webp'

const AlcoolGasolina = ({ dataLayer, setDataLayer, sendDatalayerEvent }: OpenModalShellBoxProps) => {
  const width = useWidth()
  const arrayCards = [
    {
      description: 'Qual compensa mais: álcool ou gasolina?',
      image: imageMobileOne,
      imageDesktop: imageDesktopOne,
      altText: 'Bomba de gasolina abastecendo um, carro no posto Shell',
      url: 'https://blog.inter.co/alcool-ou-gasolina/',
    },
    {
      description: 'O que é cashback e como conseguir dinheiro de volta?',
      image: imageMobileTwo,
      imageDesktop: imageDesktopTwo,
      altText: 'Mão segurando um celular com a logomarca do inter na tela',
      url: 'https://blog.inter.co/o-que-e-cashback/',
    },
    {
      description: 'Como pagar com Shell Box? Abasteça e ganhe cashback na conta',
      image: imageMobileThree,
      imageDesktop: imageDesktopThree,
      altText: 'Logo de Shell Box na frente de um posto de gasolina',
      url: 'https://blog.inter.co/como-pagar-com-shell-box/',
    },
  ]

  return (
    <SectionComponent
      id='seu-jeito-simples'
      minHeight={{ sm: '1054px', md: '535px', lg: '546px', xl: '615px', xxl: '615px' }}
    >
      <div className='col-12 d-flex justify-content-center'>
        <div>
          <GridTextTitle>
            <h2 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-48 lh-xl-52 mb-4 text-md-center'>
              Álcool, gasolina e... cashback!
            </h2>
            <p className='text-grayscale--400 fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 my-4 pb-md-2 text-md-center'>
              Confira as super dicas pra ficar de olho na hora de abastecer.
            </p>
          </GridTextTitle>
          <div className='row mx-0 align-items-start justify-content-md-center'>
            {arrayCards.map((item: {description: string; image: string; imageDesktop: string; url: string; altText: string }) => (
              <SuperDicas className='mb-4 mb-md-0 px-0 text-center' key={item.description}>
                <GridText>
                  <a
                    href={item.url}
                    target='_blank'
                    rel='noreferrer'
                    title={item.description}
                    onClick={() => {
                      setDataLayer && setDataLayer(dataLayer)
                      sendDatalayerEvent({
                        section: 'dobra_08',
                        section_name: 'Álcool, gasolina e... cashback!',
                        element_action: 'click button',
                        element_name: item.description,
                        redirect_url: item.url,
                      })
                    }}
                  >
                    <ImageWebp
                      pathSrc={width <= WIDTH_MD ? item.image : item.imageDesktop}
                      altDescription={item.altText}
                      arrayNumbers={[ 312, 216, 296, 264, 264 ]}
                      arrayNumbersHeight={[ 222, 222, 222, 222, 222 ]}
                    />
                    <p className='text-grayscale--500 fw-600 fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mt-4 mb-0 text-left'>
                      {item.description}
                    </p>
                  </a>
                </GridText>
              </SuperDicas>
              ))}
          </div>
        </div>
      </div>
    </SectionComponent>
  )
}

export default AlcoolGasolina
