import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import SeuJeitoSimples from './sections/seu-jeito-simples/_index'
import DescontoShellBox from './sections/desconto-shell-box/_index'
import EsimplesUsar from './sections/e-simples-usar/_index'
import EncontreOposto from './sections/encontre-o-posto/_index'
import BannersShellBox from './sections/banners-shell-box/_index'
import EconomizeTempo from './sections/economize-tempo/_index'
import AlcoolGasolina from './sections/alcool-gasolina/_index'
import EconomizeAindaMais from './sections/economize-ainda-mais/_index'
import FaqShellBox from './sections/faq-shell-box/_index'

import QrCodeShellBox from './assets/images/qrcode-modal-shell-box.svg'

import pageContext from './pageContext.json'

const ShellBox = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const url = 'https://intergo.app/d84ea10b'

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Shell Box - Abasteça e ganhe cashback direto na sua conta',
    element_action: 'click button',
    element_name: 'Economizar com Shell Box',
    redirect_url: url,
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Pague com Shell Box direto pelo Super App'
        subtitle='A funcionalidade de Shell Box está disponível dentro do Super App Inter. </br> </br> Acesse o QR Code para utilizar:'
        instructions='Ainda não tem conta no Inter? </br> Leia o QR Code para baixar o app e abrir sua conta gratuita.'
        isHrTop
        isHrBottom
        isNewFont
        isMinPadding
        qrCode={QrCodeShellBox}
        dataLayer={dataLayer}
        className='text-center'
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModal}
      <Hero setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} url={url} sendDatalayerEvent={sendDatalayerEvent} />
      <SeuJeitoSimples />
      <DescontoShellBox setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} url={url} sendDatalayerEvent={sendDatalayerEvent} />
      <EsimplesUsar setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} sendDatalayerEvent={sendDatalayerEvent} />
      <EncontreOposto dataLayer={dataLayer} setDataLayer={setDataLayer} sendDatalayerEvent={sendDatalayerEvent} />
      <BannersShellBox />
      <EconomizeTempo dataLayer={dataLayer} setDataLayer={setDataLayer} sendDatalayerEvent={sendDatalayerEvent} />
      <AlcoolGasolina setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} sendDatalayerEvent={sendDatalayerEvent} />
      <EconomizeAindaMais sendDatalayerEvent={sendDatalayerEvent} />
      <FaqShellBox />
    </Layout>
  )
}

export default ShellBox
