import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const SuperDicas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${device.tablet} {
    min-height: 222px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    } 
  }
  @media ${device.desktopXL} {
    margin-right: 40px;
  }
`
export const GridTextTitle = styled.div`
  max-width: 312px;
  margin: 0 auto;

  @media ${device.tablet} {
    max-width: 100%;
  }
`

export const GridText = styled.div`
  max-width: 312px;

  @media ${device.tablet} {
    max-width: 216px;
  }
 
  @media ${device.desktopXL} {
    max-width: 264px;
  }
`
