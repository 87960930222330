import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import cardJSON from '../../assets/data/card.json'

import { Card, Section } from './style'

type CardPprops = {
  icon: string;
  title: string;
  description: string;
  url: string;
}

type DataLayerPprops = {
  sendDatalayerEvent: Function;
}

const EconomizeAindaMais = ({ sendDatalayerEvent }: DataLayerPprops) => {
  return (
    <Section className='py-4 bg-grayscale--100 d-md-flex align-items-md-center'>
      <div className='container py-3'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-citrina fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-grayscale--500 fw-700 text-md-center'>
              Economize ainda mais no seu dia a dia!
            </h2>
            <DefaultCarousel
              sm={{ items: 2, partialVisibilityGutter: 5 }}
              md={{ items: 3, partialVisibilityGutter: 5 }}
              lg={{ items: 3, partialVisibilityGutter: 45 }}
              xl={{ items: 4, partialVisibilityGutter: 10 }}
              itemClass='px-1 px-md-2'
              autoPlay
              infinite
            >
              {
              cardJSON.map((item: CardPprops) => (
                <a
                  href={item.url}
                  key={item.title}
                  title={item.title}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_09',
                      section_name: 'Economize ainda mais no seu dia a dia!',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.url,
                    })
                  }}
                >
                  <Card>
                    <OrangeDsIcon icon={item.icon} size='MD' color='#ff7a00' />
                    <h3 className='font-sora fs-20 lh-25 text-primary--500 fw-600 mt-3'>
                      {item.title}
                    </h3>
                    <p className='fs-14 lh-17 text-grayscale--500 mb-0'>
                      {item.description}
                    </p>
                  </Card>
                </a>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EconomizeAindaMais
