import React, { useState } from 'react'
import SectionComponent from 'src/components/SectionComponent'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import QrCodeTagInter from './../../assets/images/qrcode-tag-inter.svg'
import QrCodeGarantirRotativo from './../../assets/images/qrcode-garantir-rotativo.svg'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { OpenModalShellBoxProps } from '../../types'

const EconomizeTempo = ({ dataLayer, setDataLayer, sendDatalayerEvent }: OpenModalShellBoxProps) => {
  const width = useWidth()
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isOpenModalRotativo, setIsOpenModalRotativo ] = useState(false)

  const urlTag = 'https://intergo.app/38c3edc8'
  const urlRotativo = 'https://intergo.app/0279c67a'

  const urlMobileTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-intertag/image.webp'
  const urlDesktopLgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-intertag/image.webp'
  const urlWebpRotativo = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-rotativo/image.webp'

  const openModal = domReady && (
    <Modal
      isModalOpen={isOpen ? isOpen : isOpenModalRotativo}
      setIsModalOpen={isOpen ? setIsOpen : setIsOpenModalRotativo} locationToRender={document.body}
    >
      <QrCodeModal
        isModal={isOpen ? isOpen : isOpenModalRotativo}
        setIsModal={isOpen ? setIsOpen : setIsOpenModalRotativo}
        title={isOpen ? 'Garantir minha Tag direto pelo Super App' : 'Garantir Rotativo Digital BH direto pelo Super App'}
        instructions='Ainda não tem conta no Inter? </br> Aponte a câmera do celular para o QR Code, baixe o app e abra sua conta gratuita.'
        isHrTop
        isHrBottom
        isNewFont
        isMinPadding
        qrCode={isOpen ? QrCodeTagInter : QrCodeGarantirRotativo}
        dataLayer={dataLayer}
        className='text-center'
      />
    </Modal>
  )

  return (
    <>
      {openModal}
      <SectionComponent
        id='desconto-shell-box'
        RowStyles='justify-content-md-start'
        minHeight={{ sm: '1324px', md: '818px', lg: '454px', xl: '586px', xxl: '586px' }}
      >
        <div className='col-12'>
          <h2 className='font-citrina text-grayscale--500 fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 mb-5 text-center text-md-left text-lg-center '>
            Economize tempo em pedágios e estacionamentos
          </h2>
        </div>
        {
          width >= WIDTH_MD &&
            <div className='col-md-6'>
              <ImageWebp
                pathSrc={urlDesktopLgXl}
                altDescription='Imagem ilustrativa de um mapa de uma cidade com marcações de localização com o símbolo da Shell.'
                arrayNumbers={[ 312, 335.63, 455, 552, 552 ]}
                arrayNumbersHeight={[ 221, 331.98, 448.41, 546, 546 ]}
                className='mx-auto d-block'
              />
            </div>
        }
        <div className='col-12 col-md-6 col-xl-4 offset-xl-1'>
          {width < WIDTH_MD &&
            <>
              <h3 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-lg-28 lh-lg-34 fs-xl-40 lh-xl-44 text-center fw-600'>Tag</h3>
              <ImageWebp
                pathSrc={urlMobileTablet}
                altDescription='Imagem ilustrativa de um mapa de uma cidade com marcações de localização com o símbolo da Shell.'
                arrayNumbers={[ 312 ]}
                arrayNumbersHeight={[ 312 ]}
                className='mx-auto d-block'
              />
            </>
          }
          {width >= WIDTH_MD &&
            <h3 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-lg-28 lh-lg-34 fs-xl-40 lh-xl-44 fw-600'>Tag</h3>
          }
          <p className='text-grayscale--500 fs-18 lh-22 my-4'>
            Garanta a Tag do Inter e faça pagamentos automáticos pra evitar filas em pedágios e estacionamentos.
          </p>
          {width <= WIDTH_MD
            ? (
              <a
                href={urlTag}
                className='btn btn--lg bg-orange--extra text-white text-none my-2'
                title='Garantir Tag'
                onClick={() => {
                  setDataLayer &&
                    setDataLayer({
                      section: 'dobra_07',
                      section_name: 'Economize tempo em pedágios e estacionamentos',
                      element_action: 'click button',
                      element_name: 'Garantir Tag',
                      redirect_url: urlTag,
                    })
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Economize tempo em pedágios e estacionamentos',
                    element_action: 'click button',
                    element_name: 'Garantir Tag',
                    redirect_url: urlTag,
                  })
                }}
              >
                Garantir Tag
              </a>
            )
          : (
            <button
              className='btn btn--lg bg-orange--extra text-white text-none my-2'
              title='Garantir Tag'
              onClick={() => {
                setIsOpen(true)
                setDataLayer && setDataLayer({
                  section: 'dobra_07',
                  section_name: 'Economize tempo em pedágios e estacionamentos',
                  element_action: 'click button',
                  element_name: 'Garantir Tag',
                })
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Economize tempo em pedágios e estacionamentos',
                  element_action: 'click button',
                  element_name: 'Garantir Tag',
                })
              }}
            >
              Garantir Tag
            </button>
          )
          }
        </div>
        <div className='col-md-6 pt-5 pt-md-0 order-md-last offset-xl-1'>
          {width < WIDTH_MD &&
            <h3 className='font-citrina text-grayscale--500 fs-28 lh-34 fs-xl-40 lh-xl-44 text-center fw-600'>
              Rotativo Digital BH
            </h3>
          }
          <ImageWebp
            pathSrc={urlWebpRotativo}
            altDescription='Imagem ilustrativa de um mapa de uma cidade com marcações de localização com o símbolo da Shell.'
            arrayNumbers={[ 312, 335.63, 456, 552, 552 ]}
            arrayNumbersHeight={[ 312, 331.98, 451.04, 546, 546 ]}
            className='mx-auto d-block'
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4 offset-xl-1'>
          {width >= WIDTH_MD &&
            <h4 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-lg-28 lh-lg-34 fs-xl-40 lh-xl-44 text-md-right fw-600'>
              Rotativo Digital BH
            </h4>
          }
          <p className='text-grayscale--500 fs-18 lh-22 my-4 text-lg-right'>
            Se você mora em Belo Horizonte, ainda pode comprar o Rotativo Digital BH direto pelo Super App. Aproveita!
          </p>
          {width <= WIDTH_MD
            ? (
              <a
                href={urlRotativo}
                className='btn btn--lg bg-orange--extra text-white text-none mt-2 float-lg-right'
                title='Garantir Rotativo Digital'
                onClick={() => {
                  setDataLayer && setDataLayer({
                    section: 'dobra_07',
                    section_name: 'Economize tempo em pedágios e estacionamentos',
                    element_action: 'click button',
                    element_name: 'Garantir Rotativo Digital',
                    redirect_url: urlRotativo,
                  })
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Economize tempo em pedágios e estacionamentos',
                    element_action: 'click button',
                    element_name: 'Garantir Rotativo Digital',
                    redirect_url: urlRotativo,
                  })
                }}
              >
                Garantir Rotativo Digital
              </a>
              )
              : (
                <button
                  className='btn btn--lg bg-orange--extra text-white text-none mt-2 float-lg-right'
                  title='Garantir Rotativo Digital'
                  onClick={() => {
                    setIsOpenModalRotativo(true)
                    setDataLayer && setDataLayer({
                      section: 'dobra_07',
                      section_name: 'Economize tempo em pedágios e estacionamentos',
                      element_action: 'click button',
                      element_name: 'Garantir Rotativo Digital',
                    })
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Economize tempo em pedágios e estacionamentos',
                      element_action: 'click button',
                      element_name: 'Garantir Rotativo Digital',
                    })
                  }}
                >
                  Garantir Rotativo Digital
                </button>
              )
          }
        </div>
      </SectionComponent>
    </>
  )
}

export default EconomizeTempo
