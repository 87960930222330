import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { OpenModalShellBoxProps } from '../../types'

import { SectionHeroShellBox } from './styles'

const HeroShellbox = ({ setOpenModal, dataLayer, setDataLayer, sendDatalayerEvent, url }: OpenModalShellBoxProps) => {
  const width = useWidth()

  return (
    <SectionHeroShellBox
      id='abasteca-e-ganhe-cashback'
      role='img'
      aria-label='Imagem de fundo Banner Shell Box'
      className='d-flex align-items-center'
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
            <h1 className='font-citrina text-white fs-20 lh-24 fs-md-24 lh-md-28'>Shell Box</h1>
            <h2 className='font-citrina text-white fs-28 lh-34 fs-md-40 lh-md-44 mt-2 mb-3'>
              Abasteça e ganhe cashback direto na sua conta
            </h2>
            <p className='text-white fs-18 lh-22'>
              Use a funcionalidade <strong>Shell Box</strong> dentro do <strong>Super App Inter</strong> pra pagar na hora de abastecer em postos Shell.
            </p>
            <p className='text-white fs-18 lh-22 mb-4'>
              Parte do valor pago pelo combustível volta pra você na mesma hora.
            </p>
            {width <= WIDTH_MD
            ? (
              <a
                href={url}
                title='Economizar com Shell Box'
                className='btn btn--lg bg-white text-orange--extra text-none'
                onClick={() => {
                  setDataLayer && setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Economizar com Shell Box
              </a>
            )
          : (
            <button
              className='btn btn--lg bg-white text-orange--extra text-none'
              title='Economizar com Shell Box'
              onClick={() => {
                setDataLayer && setDataLayer(dataLayer)
                setOpenModal && setOpenModal(true)
                sendDatalayerEvent({
                  ...dataLayer,
                  redirect_url: null,
                })
              }}
            >
              Economizar com Shell Box
            </button>
          )
        }
          </div>
          <div className='col-12 col-md-6'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-hero/image.webp'
              altDescription='Celular exibindo a tela de Shell Box no Super App Inter com uma bomba de combustível laranja saindo da tela.'
              arrayNumbers={[ 328, 328, 456, 593, 593 ]}
              arrayNumbersHeight={[ 283, 283, 395, 475, 475 ] }
              className='float-md-right'
            />
          </div>

        </div>
      </div>
    </SectionHeroShellBox>
  )
}

export default HeroShellbox
