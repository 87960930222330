import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import ImageWebp from 'src/components/ImageWebp'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { OpenModalShellBoxProps } from '../../types'
import { ListShellBox } from './styles'
import OpenVideo from 'src/components/OpenVideo'

const EsimplesUsar = ({ setOpenModal, dataLayer, setDataLayer, sendDatalayerEvent }: OpenModalShellBoxProps) => {
  const width = useWidth()
  const url = 'https://intergo.app/5bc331a2'

  const urlMobileWebp = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-36-video/image.webp'
  const imageTabletWebp = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-768-video/image.webp'
  const imageDesktopWebp = 'https://central-imagens.bancointer.com.br/images-without-small-versions/shellbox-1440-video/image.webp'

  const HandleClick = () => {
    setDataLayer && setDataLayer(dataLayer)
    sendDatalayerEvent({
      section: 'dobra_04',
      section_name: 'É simples usar o Shell Box pelo Super App e economizar na hora de abastecer:',
      element_action: 'click button',
      element_name: 'Consulte aqui o mapa',
      redirect_url: url,
    })
  }

  return (
    <SectionComponent
      id='e-simples-usar-o-shell-box'
      minHeight={{ sm: '769px', md: '517px', lg: '518px', xl: '652px', xxl: '652px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 pr-md-0 pr-xl-4 order-md-last'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-40 lh-xl-44 mb-4'>
          Como pagar com <span className='text-orange--extra'>Shell Box</span>
        </h2>
        <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400'>É simples <strong>abastecer com Shell Box</strong> dentro do Super App Inter. Confira o passo a passo: </p>
        {width < WIDTH_MD &&
          <OpenVideo
            link='https://www.youtube.com/embed/q_-nVAEbWZE?si=YMwuAX6t7eIrbNK0&autoplay=1'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: 'É simples usar o Shell Box pelo Super App e economizar na hora de abastecer:',
                element_action: 'click button',
                element_name: 'Pagar com Shell Box',
                redirect_url: 'https://www.youtube.com/embed/q_-nVAEbWZE?si=YMwuAX6t7eIrbNK0&autoplay=1',
              })
            }}
          >
            <ImageWebp
              pathSrc={urlMobileWebp}
              altDescription='Desconto no combustível no Super App do Inter com Shell Box'
              arrayNumbers={[ 312 ]}
              arrayNumbersHeight={[ 300 ]}
              className='mx-auto d-block cursor-pointer'
            />
          </OpenVideo>
        }
        <ListShellBox className='text-grayscale--400 fs-14 lh-17 fs-md-16 lh-md-20 my-4 d-flex align-items-center flex-column'>
          <li><span className='text-grayscale--500 fs-24 lh-30 fw-700 pr-2 pr-lg-3'>1</span><span>Dirija-se até um <strong>posto de gasolina Shell</strong> que aceite o pagamento via app Shell Box <a href={url} className='d-block text-primary--500 fw-600' title='Consulte aqui o mapa' target='_blank' rel='noreferrer' onClick={HandleClick}>Consulte aqui o mapa</a></span></li>
          <li><span className='text-grayscale--500 fs-24 lh-30 fw-700 pr-2 pr-lg-3'>2</span>Acesse a área de Shell Box no Super App;</li>
          <li><span className='text-grayscale--500 fs-24 lh-30 fw-700 pr-2 pr-lg-3'>3</span>Insira o código de pagamento que está na placa ao lado da bomba de combustível;</li>
          <li><span className='text-grayscale--500 fs-24 lh-30 fw-700 pr-2 pr-lg-3'>4</span>Toque em "Abastecer" e pronto! </li>
        </ListShellBox>
        {width <= WIDTH_MD
          ? (
            <a
              href={url}
              className='btn btn--lg bg-orange--extra text-white text-none'
              title='Pagar com Shell Box'
              onClick={() => {
                setDataLayer && setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'É simples usar o Shell Box pelo Super App e economizar na hora de abastecer:',
                  element_action: 'click button',
                  element_name: 'Pagar com Shell Box',
                  redirect_url: url,
                })
              }}
            >
              Pagar com Shell Box
            </a>
          )
        : (
          <button
            className='btn btn--lg bg-orange--extra text-white text-none'
            title='Pagar com Shell Box'
            onClick={() => {
              setDataLayer && setDataLayer(dataLayer)
              setOpenModal && setOpenModal(true)
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: 'É simples usar o Shell Box pelo Super App e economizar na hora de abastecer:',
                element_action: 'click button',
                element_name: 'Pagar com Shell Box',
              })
            }}
          >
            Pagar com Shell Box
          </button>
        )
      }
      </div>
      {width >= WIDTH_MD &&
        <div className='col-12 col-md-6'>
          <OpenVideo
            link='https://www.youtube.com/embed/q_-nVAEbWZE?si=YMwuAX6t7eIrbNK0&autoplay=1'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_04',
                section_name: 'É simples usar o Shell Box pelo Super App e economizar na hora de abastecer:',
                element_action: 'click video',
                element_name: 'Assista ao vídeo',
                redirect_url: 'https://www.youtube.com/embed/q_-nVAEbWZE?si=YMwuAX6t7eIrbNK0&autoplay=1',
              })
            }}
          >
            <ImageWebp
              pathSrc={width === WIDTH_MD ? imageTabletWebp : imageDesktopWebp}
              altDescription='Desconto no combustível no Super App do Inter com Shell Box'
              arrayNumbers={[ 312, 336, 456, 552, 552 ]}
              arrayNumbersHeight={[ 300, 391, 389.91, 472, 472 ]}
              className='cursor-pointer'
            />
          </OpenVideo>
        </div>
      }
    </SectionComponent>
  )
}

export default EsimplesUsar
